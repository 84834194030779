import React, { useEffect, useRef } from 'react';
import { EffectComposer, RenderPass, ShaderPass } from 'three-stdlib';
import { extend, useFrame, useThree } from '@react-three/fiber';
import RippleShader from './RippleShader';
import { useCursor } from '@lam-agency/toolkit/hooks';

extend({ EffectComposer, RenderPass, ShaderPass });

const Effects = ({ dimensions }) => {
  const pos = useCursor();
  const composerRef = useRef(null);
  const { gl, scene, camera, size } = useThree();

  useEffect(() => {
    if (!composerRef?.current) {
      return;
    }

    composerRef.current.setSize(size.width, size.height);
  }, [composerRef.current, size]);

  useFrame(() => {
    if (!composerRef?.current) {
      return;
    }

    composerRef.current.render();
  }, 1);

  return (
    <effectComposer ref={composerRef} args={[gl]}>
      <renderPass attach="passes-0" args={[scene, camera]} />
      <RippleShader dimensions={dimensions} pos={pos} />
    </effectComposer>
  );
};

export default Effects;
