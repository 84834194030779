import React, { useEffect, useRef, useState } from 'react';
import { useMVT } from '@lam-agency/toolkit/stores';
import { Canvas } from '@react-three/fiber';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { parseImagesFromFigma } from '@lam-agency/toolkit/utils/figma';
import {
  ATFWidget,
  LayoutProvider,
  TAnimation,
  TextAnimation
} from '@lam-agency/toolkit/components';
import {
  useBreakpoints,
  useScroll,
  useWindowDimensions
} from '@lam-agency/toolkit/hooks';
import { getCSSVariableValue } from '@lam-agency/toolkit/utils';

import Effects from './Effects';
import { IWidgetProps } from '@lam-agency/toolkit/components/Common/ATFWidget';

interface IFields extends IWidgetProps {
  body: string;
  images: string[];
}
interface IProps {
  data: {
    fields: IFields;
  };
}

const FigmaCopyOnImage = ({
  data: {
    fields: { assetLinks, body, images, contactURL, quickLinks }
  }
}: IProps) => {
  const { projectString } = useMVT();
  const { scrollY } = useScroll();
  const { height, width } = useWindowDimensions();
  const { tablet } = useBreakpoints();

  const containerRef = useRef<HTMLDivElement>(null);

  const allImages = parseImagesFromFigma(projectString, images);

  const [dimensions, setDimensions] = useState({
    width: 0,
    normalizedWidth: 0,
    height: 0,
    normalizedHeight: 0,
    aspect: 0
  });

  const getCanvasClipPath = () => {
    if (scrollY > height) return 'none';
    const top = Math.max(height - scrollY, 0);
    const bottom = height;

    return `polygon(0px ${top}px, ${width}px ${top}px, ${width}px ${bottom}px, 0px ${bottom}px)`;
  };

  const amountToScrollBeforeBlurPx = 300; // sync with amoutToScrollBeforeFill in Header.tsx
  const blurMultiplier = 0.02;
  const normalisedScroll = Math.max(0, scrollY - amountToScrollBeforeBlurPx);
  const blurAmountPx = normalisedScroll * blurMultiplier;

  const darkenMultiplier = 0.002;
  const maxDarkenOpacity = 0.75;
  const darkenOpacity = Math.min(
    maxDarkenOpacity,
    normalisedScroll * darkenMultiplier
  );

  const imageScaleMultiplier = 0.0002;
  const maxScale = 1.1;
  const minScale = 1;

  const imageScale = Math.max(
    minScale,
    maxScale - normalisedScroll * imageScaleMultiplier
  );

  const textAnimation = getCSSVariableValue(
    '--atf-text-animation'
  ) as TAnimation;

  //

  const pixelsToCamera = (pixelWidth: number) => {
    const SCALE_FACTOR = 0.01;

    return pixelWidth * SCALE_FACTOR;
  };

  //

  useEffect(() => {
    if (!containerRef?.current || typeof window === `undefined`) {
      return;
    }

    const handleResize = () => {
      if (!containerRef.current) return;

      const boundingRect = containerRef.current.getBoundingClientRect();

      const { width, height } = boundingRect;

      const aspect = width / height;
      const cameraWidth = pixelsToCamera(width);

      setDimensions({
        width,
        height,
        aspect,
        normalizedWidth: cameraWidth,
        normalizedHeight: cameraWidth * (1 / aspect)
      });
    };

    window.addEventListener(`resize`, handleResize);

    handleResize();

    return () => {
      window.removeEventListener(`resize`, handleResize);
    };
  }, []);

  //

  return (
    <>
      <div ref={containerRef} className={styles.container}>
        {/* todo: how would we know which image to use? */}
        {allImages?.[0]?.image && (
          <img
            aria-hidden
            className={styles.background}
            src={allImages[0].image}
            alt=""
            style={{ transform: `scale(${imageScale})` }}
          />
        )}

        <LayoutProvider grid paddingX paddingY className={styles.layout}>
          <div className={styles.textWrapper}>
            <TextAnimation
              className={cn('d1')}
              text={body}
              animation={textAnimation}
              speed={0.03}
            />
          </div>

          <ATFWidget
            contactURL={contactURL}
            assetLinks={assetLinks}
            quickLinks={quickLinks}
          />
        </LayoutProvider>

        <div
          aria-hidden
          style={{
            backdropFilter: `blur(${blurAmountPx}px)`,
            backgroundColor: `rgba(0, 0, 0, ${darkenOpacity})`
          }}
          className={styles.blur}
        />
      </div>

      <div aria-hidden className={styles.scrollPadding} />

      {/* putting this here as it needs to appear behind every slice on the page */}
      {tablet && (
        <div
          className={styles.canvasContainer}
          style={{
            clipPath: getCanvasClipPath()
          }}
          key={`${width}${height}`}
        >
          <Canvas
            camera={{ position: [0, 0, 100], near: 0.1, far: 100, zoom: 100 }}
            orthographic
            className={styles.canvas}
          >
            <ambientLight intensity={1.0} />

            <mesh>
              <planeGeometry
                // @dog - how can we just make this the full size of container?
                args={[
                  dimensions.normalizedWidth * 0.999, // For some reason...
                  dimensions.normalizedHeight * 0.999, // ... this doesn't display at full scale
                  1024,
                  1024
                ]}
              />
              <meshBasicMaterial color="white" toneMapped={false} />
            </mesh>

            <Effects dimensions={dimensions} />
          </Canvas>
        </div>
      )}
    </>
  );
};

export default FigmaCopyOnImage;
